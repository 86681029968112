import { Controller } from "stimulus";
import Swiper, { Navigation } from "swiper";

export default class extends Controller {
  static targets = ["swiper"];

  connect() {
    Swiper.use([Navigation]);
    this.swiper = new Swiper(this.swiperTarget, {
      loop: false,
      slidesPerView: 1,
      centeredSlides: true,
      centeredSlidesBounds: true,
      navigation: {
        nextEl: ".media-mentions__carousel-btn--next",
        prevEl: ".media-mentions__carousel-btn--prev",
      },
      breakpoints: {
        1024: {
          slidesPerView: 2,
          centeredSlides: false,
        },
        1440: {
          slidesPerView: 3,
          centeredSlides: true,
        },
      },
    });
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }
}
