import Rails from "@rails/ujs";
import Controller from "./sticky_header_controller";

export default class extends Controller {
  static targets = [...Controller.targets, "menu", "burgerMenu", "navigation", "search", "form"];
  static values = {
    ...Controller.values,
    isOpen: Boolean,
  };

  connect() {
    super.connect();
  }

  isOpenValueChanged() {
    if (this.isOpenValue) {
      this.headerTarget.classList.add("header--search-shown");
      this.formTarget.classList.add("search__form--visible");
    } else {
      this.formTarget.classList.remove("search__form--visible");
    }
  }

  submit(e) {
    Rails.fire(this.formTarget, "submit");
  }

  focused(e) {
    this.isOpenValue = true;
  }

  blured(e) {
    if (!this.searchTarget.value) {
      this.isOpenValue = false;
    }
  }

  clear(e) {
    this.searchTarget.value = "";
    this.isOpenValue = false;
  }

  toggleMenu(e) {
    this.menuTarget.classList.toggle('header__menu-body--active');
    this.burgerMenuTarget.classList.toggle('burger-menu--active');
    document.body.classList.toggle('overflow-hidden');
  }

  formTransitionend(e) {
    if (!this.isOpenValue) {
      this.headerTarget.classList.remove("header--search-shown");
    }
  }
}
