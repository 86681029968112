import { Controller } from "stimulus";
import Swiper, { Navigation } from "swiper";
import anime from "animejs/lib/anime.es.js";

export default class extends Controller {
  static targets = ["swiper"];

  connect() {
    Swiper.use([Navigation]);
    this.currentTransitionSpeed = 0;

    this.swiper = new Swiper(this.swiperTarget, {
      loop: true,
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 10,
      centeredSlidesBounds: true,
      grabCursor: true,
      navigation: {
        nextEl: ".volunteers__carousel-btn--next",
        prevEl: ".volunteers__carousel-btn--prev",
      },
      effect: "slide",
      watchSlidesProgress: false,
      virtualTranslate: false,
      breakpoints: {
        576: {
          slidesPerView: 2,
          centeredSlides: false,
          centeredSlidesBounds: false,
          effect: "slide",
          watchSlidesProgress: false,
          virtualTranslate: false,
        },
        992: {
          speed: 800,
          slidesPerView: 3,
          centeredSlides: true,
          centeredSlidesBounds: true,
          effect: "customSlideWithScale",
          watchSlidesProgress: true,
          virtualTranslate: true,
        },
      },
      on: {
        setTransition: (swiper, transition) => {
          if (swiper.params.effect !== "customSlideWithScale") {
            this.resetSlidesScale(swiper);
            return;
          }

          this.setTransition(swiper, transition);
        },
        setTranslate: (swiper, translate) => {
          if (swiper.params.effect !== "customSlideWithScale") {
            this.resetSlidesScale(swiper);
            return;
          }

          this.setTranslate(swiper, translate);
        },
      },
    });
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  resetSlidesScale(swiper) {
    const slides = Object.values(swiper.slides).slice(0, -1);

    slides.forEach((slide) => {
      anime({
        targets: slide,
        duration: 0,
        scale: 1,
        translateX: 0,
      });

      anime({
        targets: slide.querySelector('.volunteer__image'),
        duration: 0,
        scale: 1,
        translateX: 0,
      });
    });
  }

  getTransitionSpeed() {
    const transitionSpeed = this.currentTransitionSpeed;

    this.currentTransitionSpeed = 0;
    return transitionSpeed;
  }

  setTransition(swiper, transitionSpeed) {
    this.currentTransitionSpeed = transitionSpeed;
  }

  setTranslate(swiper, wrapperTranslate) {
    const duration = this.getTransitionSpeed();
    const slides = Object.values(swiper.slides).slice(0, -1);
    swiper.$wrapperEl.transform(`translateX(${wrapperTranslate}px)`);

    // Known issue: Last slide scale is not applied
    slides.forEach((slide) => {
      const progress = slide.progress + 1;

      const ZOOM_FACTOR = 0.35;
      const IMAGE_ZOOM_FACTOR = 0.1;

      const clippedProgress = Math.max(-1, Math.min(progress, 1));
      const scale = 1 - ZOOM_FACTOR * Math.abs(clippedProgress);
      const imageScale = 1 - IMAGE_ZOOM_FACTOR * Math.abs(clippedProgress);

      anime({
        targets: slide,
        duration,
        translateX: `${-17 * clippedProgress}%`,
        scale,
        easing: "easeOutCubic",
      });

      anime({
        targets: slide.querySelector('.volunteer__image'),
        duration,
        scale: imageScale,
        translateX: `${-5 * clippedProgress}%`,
      });
    });
  }
}
