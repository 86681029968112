import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["header"];

  connect() {
    this.updateHeaderClasses();
  }

  onScroll(e) {
    this.updateHeaderClasses();
  }

  updateHeaderClasses() {
    if (window.pageYOffset > 0) {
      this.headerTarget.classList.add("header--fixed");
    } else {
      this.headerTarget.classList.remove("header--fixed");
    }
  }
}
