import { Controller } from "stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["menu", "toggler"];
  static classes = ["active"];
  static values = {
    open: Boolean,
  };

  connect() {
    useClickOutside(this, {
      element: this.menuTarget,
    });

    this.openValue = false;
  }

  toggle() {
    this.openValue = !this.openValue;
  }

  clickOutside(e) {
    if (
      !this.openValue ||
      e.target == this.togglerTarget ||
      this.togglerTarget.contains(e.target)
    )
      return;
    this.element.classList.remove(this.activeClass);
  }

  openValueChanged() {
    if (this.openValue) {
      this.element.classList.add(this.activeClass);
    } else {
      this.element.classList.remove(this.activeClass);
    }
  }
}
