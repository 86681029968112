import { Controller } from "stimulus";
import Swiper, { Navigation, Grid } from "swiper";

export default class extends Controller {
  static targets = ["swiper"];

  connect() {
    Swiper.use([Navigation, Grid]);

    this.swiper = new Swiper(this.element, {
      autoHeight: false,
      spaceBetween: 24,
      slidesPerView: 2,
      grid: {
        rows: 2,
        fill: "row",
      },
      navigation: {
        prevEl: ".partners-carousel-btn--prev",
        nextEl: ".partners-carousel-btn--next",
      },
      breakpoints: {
        1200: {
          slidesPerView: 8,
        },
        992: {
          slidesPerView: 6,
        },
        768: {
          slidesPerView: 4,
        },
        350: {
          slidesPerView: 3,
        },
      },
    });
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }
}
