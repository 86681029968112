import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let i = 0;

    // prevent posibility of stack overflow
    while (i++ < 100) {
      if (this.element.scrollHeight <= this.element.offsetHeight) break;

      let innerHTML = this.element.innerHTML;
      this.element.innerHTML =
        innerHTML.slice(0, innerHTML.length - 6).trim() + "...";
    }
  }
}
